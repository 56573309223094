@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;500&family=Oswald:wght@700&display=swap');

.app{
  overflow-x: hidden;
}

@font-face {
  font-family: "BespokeStencil";
  src: url("./Assets/BespokeStencil-Bold.otf")
}
.headers {
  font-family: "BespokeStencil",sans-serif !important
};

@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");

html,
body {
  width: 100%;
  height: 100%;
  background-color: #ffb3b3;
  display: grid;
  place-content: center;
}

.navbar__menu-item {
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  font-size: 4.4em;
  padding: 60px 20px;
  font-weight: 700;
  width: 300px;
  height: 100px;
  position: relative;
  cursor: pointer;
}

.navbar__menu-item-title {
  position: relative;
  color: transparent;
  -webkit-text-stroke: 0.01em white;
  transition: -webkit-text-stroke 0.5s ease, color 0.5s ease;
}

.navbar__menu-item:hover .navbar__menu-item-title {
  -webkit-text-stroke: none;
  color: white;
}

.navbar__menu-item-title::after {
  content: attr(data-menu-item-text);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-weight: 900;
  -webkit-text-stroke: 0.012em white;
  color: transparent;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.menu-item-hover-image {
  width: 198px;
  height: 230px;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 75%;
  opacity: 0;
  transition: opacity 0.3s, transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  /* This places the cursor in the center of the image. Divide the width and height by 2 to get the top and left offsets :) */
  margin: -115px 0 0 -99px;
  overflow: hidden;
  pointer-events: none;
  z-index: 2;
}

.navbar__menu-item:hover .menu-item-hover-image {
  opacity: 1;
}
.navbar__menu-item:hover .navbar__menu-item-title::after {
  opacity: 1;
  z-index: 3;
}

.ContBtn{
  background-color: #ff0000 !important;
  margin-top: 25px !important;
  
}

.slider-control-bottomcenter{
  display: none !important;
}

.scrollButtons{
  border: 1px solid black !important;
  margin: 0 5px !important;
  color: black !important;
}

/* .glitchher */

.container {
  text-align: center;
}

.glitchh {
  font-size: 5rem;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff,
    0.025em 0.04em 0 #fffc00;
  animation: glitchh 725ms infinite;
}

.glitchh > span {
  position: absolute;
  top: 0;
  left: 0;
}

.glitchh > span:first-child {
  animation: glitchh 500ms infinite;
  clip-path: polygon(0 0, 100% 0, 100% 35%, 0 35%);
  transform: translate(-0.04em, -0.03em);
  opacity: 0.75;
}

.glitchh > span:last-child {
  animation: glitchh 375ms infinite;
  clip-path: polygon(0 65%, 100% 65%, 100% 100%, 0 100%);
  transform: translate(0.04em, 0.03em);
  opacity: 0.75;
}

@keyframes glitchh {
  0% {
    text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff,
      0.025em 0.04em 0 #fffc00;
  }
  15% {
    text-shadow: 0.05em 0 0 #00fffc, -0.03em -0.04em 0 #fc00ff,
      0.025em 0.04em 0 #fffc00;
  }
  16% {
    text-shadow: -0.05em -0.025em 0 #00fffc, 0.025em 0.035em 0 #fc00ff,
      -0.05em -0.05em 0 #fffc00;
  }
  49% {
    text-shadow: -0.05em -0.025em 0 #00fffc, 0.025em 0.035em 0 #fc00ff,
      -0.05em -0.05em 0 #fffc00;
  }
  50% {
    text-shadow: 0.05em 0.035em 0 #00fffc, 0.03em 0 0 #fc00ff,
      0 -0.04em 0 #fffc00;
  }
  99% {
    text-shadow: 0.05em 0.035em 0 #00fffc, 0.03em 0 0 #fc00ff,
      0 -0.04em 0 #fffc00;
  }
  100% {
    text-shadow: -0.05em 0 0 #00fffc, -0.025em -0.04em 0 #fc00ff,
      -0.04em -0.025em 0 #fffc00;
  }
}
