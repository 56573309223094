@tailwind base;
@tailwind components;
@tailwind utilities;


.usecaseImgCont{
    background-image: url('../src/Assets/homeBanner.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.bgImg{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.processHero{
    background-image: url('../src/Assets/First\ image\ -\ Crafting\ immersive.png');
}

.designNDev{
    background-image: url('../src/Assets/Development\ New.png');
}

.launchnDevImg{
    background-image: url('../src/Assets/Launch.png');
}

.discoverImg{
    background-image: url('../src/Assets/Discovery\ new.png');
}

.consultingImg{
    background-image: url('../src/Assets/COnsulting\ and\ Staffing.png');
}

.midImg1{
    background-image: url('../src/Assets/Title\ Middle\ image.png');
}

.footerBg{
    background-image: url('../src/Assets/footerbg.png');
    background-position-y: bottom;
}

.usecaseImgCont2{
    background-image: url('../src/Assets/homeBanner.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.usecaseImgCont3{
    background-image: url('../src/Assets/statue.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.greenBtn{
    @apply mt-12 rounded-3xl bg-yellow-200 text-green-900 hover:bg-yellow-300 !important;
}

.btnWhite{
    @apply flex my-7 md:px-12 rounded-3xl mx-auto bg-white text-black hover:bg-white !important;
} 

.btnBlack{
    @apply flex  my-7 rounded-3xl mx-auto md:px-12 bg-black text-white hover:bg-black !important;
} 

.btnBlack2{
    @apply flex  mt-2 rounded-3xl mx-auto md:mx-2 md:px-12 bg-black text-white hover:bg-black !important;
}

.btnBlack3{
    @apply flex my-4 mb-0 rounded-3xl mx-auto md:mx-2 md:px-12 bg-black text-white hover:bg-black !important;
}

.slider-container{
    height: inherit;
}

.slider-control-centerright{
    display: none;
}
.slider-control-centerleft{
    display: none;
}

@font-face {
    font-family: "supreme";
    src: url("./Assets/Supreme_Complete/Fonts/OTF/Supreme-Medium.otf")
  }  
.content{
    font-family: "supreme",sans-serif !important
  }

.MainBanner{
    background-image: url('../src/Assets/Landing page .png');
    background-position-y: -150px ;
}

@media (min-width:900px){
    .MainBanner{
        background-position-y: -100px ;
    }
}

.weAreMOk{
    background-image: url('../src/Assets/We\ are\ mokmetA.png');
    background-position-y: -80px !important;
}

.buildImmensive{
    background-image: url('../src/Assets/Build\ Immersive\ Experiences.png');
}

.createVirtual{
    background-image: url('../src/Assets/Create\ Virtual\ Worlds.png');
}

.DigRealms{
    background-image: url('../src/Assets/Digital\ Realms.png');
}

.boundLess{
    background-image: url('../src/Assets/Boundless-\ Footer.png');
}

.possiBilities{
    background-image: url('../src/Assets/Possibilities.png');
    background-position-y: -40px !important;
}

.aboutTopBanner{
    background-image: url('../src/Assets/Eddy_astronaut_surfing_through_space_planets_stars_universe_gal_22c849e6-4be2-4f6b-b0b1-2fdf06a805f0.png');
}

.valuesBanner{
    background-image: url('../src/Assets/Values.png');
}

.ourPeople{
    background-image: url('../src/Assets/Our\ People.png');
}

.capBannner{
    background-image: url('../src/Assets/Capabilities.png');
}

.ARvr{
    background-image: url('../src/Assets/ARVR\ XR.png');
}

.DigTwins{
    background-image: url('../src/Assets/Digital\ Twins\ 1.png');
}

.virtWorld{
    background-image: url('../src/Assets/Virtual\ Worlds1.png');
}

.ThreedVisual{
    background-image: url('../src/Assets/3D\ visualizations\ 1.png');
}

.usecase1{
    background-image: url('../src/Assets/1.png');
    background-position-y: 0px !important;
}

.usecase2{
    background-image: url('../src/Assets/2.png');
    background-position-x: 0px !important;
}

.usecase3{
    background-image: url('../src/Assets/3.png');
    background-position-y: -40px !important;
}

.usecase4{
    background-image: url('../src/Assets/4.png');
}

.EComm{
    background-image: url('../src/Assets/Retail\ and\ E\ commerce.png');
}

.manuFact{
    background-image: url('../src/Assets/Manufacturing.png');
}

.realEst{
    background-image: url('../src/Assets/Real\ Estate.png');
}

.healthCAre{
    background-image: url('../src/Assets/Healthcare.png');
    background-position-y: -290px !important;
}

.TourIsm{
    background-image: url('../src/Assets/Travel\ and\ Tourism.png');
    background-position-y: -100px !important;
}

.EDUcation{
    background-image: url('../src/Assets/Education.png');
}

.FinTeCH{
    background-image: url('../src/Assets/Fintech.png');
    background-position-y: -50px !important;
}

.futureIs{
    background-image: url('../src/Assets/Future\ is\ Now.png');
}

.staterGy{
    background-image: url('../src/Assets/Metaverse\ Strategy.png');
    background-position-y: -80px !important;
}

.devLopment{
    background-image: url('../src/Assets/Metaverse\ Development.png');
}

.ConstLting{
    background-image: url('../src/Assets/Metaverse\ Consulting.png');
}

.DemaNd{
    background-image: url('../src/Assets/Staffing\ Talent\ on\ Demand.png');
}

.deveLopement{
    background-image: url('../src/Assets/Product\ Development.png');
}

.metaStrategy2{
    background-image: url('../src/Assets/Meta Strategy 2.png');
}

.metadev2{
    background-image: url('../src/Assets/Metaverse\ Development\ 1.png');
}

.metaconsult2{
    background-image: url('../src/Assets/Metaverse\ Consulting\ 1.png');
}

.metaStaff2{
    background-image: url('../src/Assets/Metaverse\ Talent\ On\ Demand\ 2.png');
}

.metpProd2{
    background-image: url('../src/Assets/Product\ Development\ 1.png');
}

.stratImg1{
    background-image: url('../src/Assets/ANALYSIS.jpg');
}

.stratImg2{
    background-image: url('../src/Assets/OPPORTUNITY\ ASSESSMENT.jpg');
}

.stratImg3{
    background-image: url('../src/Assets/ROADMAP\ DEVELOPMENT.jpg');
}

.stratImg4{
    background-image: url('../src/Assets/BUSINESS MODEL DEVELOPMENT.jpg');
}

.metdaDevImg1{
    background-image: url('../src/Assets/1\ Experience\ Design.jpg');
}

.metdaDevImg2{
    background-image: url('../src/Assets/2\ Content\ Creation.jpg');
}

.metdaDevImg3{
    background-image: url('../src/Assets/3\ Platform\ Development.jpg');
}

.metdaDevImg4{
    background-image: url('../src/Assets/4\ API\ Development.jpg');
}

.consultIMg1{
    background-image: url('../src/Assets/1\ TECHNOLOGY\ ASSESSMENT.jpg');
}

.consultIMg2{
    background-image: url('../src/Assets/2\ MARKET\ ANALYSIS.jpg');
}

.consultIMg3{
    background-image: url('../src/Assets/3\ LEGAL\ AND\ REGULATORY.jpg');
}

.consultIMg4{
    background-image: url('../src/Assets/4\ PLATFORM\ SELECTION.jpg');
}

.consultIMg5{
    background-image: url('../src/Assets/5\ CONTENT\ CREATION\ AND\ CURATION.jpg');
}

.consultIMg6{
    background-image: url('../src/Assets/6\ USER\ EXPERIENCE\ DESIGN.jpg');
}

.onDemageIMg1{
    background-image: url('../src/Assets/1\ DEVELOPERS.jpg');
}

.onDemageIMg2{
    background-image: url('../src/Assets/2\ DESIGNERS.jpg');
}

.onDemageIMg3{
    background-image: url('../src/Assets/3\ USER\ EXPERIENCE.jpg');
}

.onDemageIMg4{
    background-image: url('../src/Assets/4\ CONTENT\ CREATORS.jpg');
}

.onDemageIMg5{
    background-image: url('../src/Assets/5\ MARKETING\ AND\ ADVERTISING.jpg');
}

.DevmntImg1{
    background-image: url('../src/Assets/1\ CONCEPT.jpg');
}

.DevmntImg2{
    background-image: url('../src/Assets/2\ PRODUCT\ DESIGN.jpg');
}

.DevmntImg3{
    background-image: url('../src/Assets/3\ PRODUCT\ TESTING.jpg');
}

.DevmntImg4{
    background-image: url('../src/Assets/4\ PRODUCT\ LAUNCH.jpg');
}

.btn {		
	position: relative;
	transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);	
	cursor: pointer;
}

.btn:before, .btn:after {
	content: '';
	position: absolute;	
	transition: inherit;
	z-index: -1;
}

.btn:hover {
	color: white;
    overflow: hidden;
	/* transition-delay: .5s; */
}

.footBg {
    background: #56CCF2 !important;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2F80ED, #56CCF2) !important;
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2F80ED, #56CCF2) !important;
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.btn:hover:before {
	transition-delay: 0s;
}

.btn:hover:after {
	background: var(--inv);
    scale: 1.1;
	/* transition-delay: .35s; */
}


.from-bottom:before, 
.from-bottom:after {
	left: 0;
	height: 0;
	width: 100%;
}

.from-bottom{
    overflow: hidden;
}

.from-bottom:before {
	top: 0;	
	/* border: 1px solid var(--inv); */
	border-top: 0;
	border-bottom: 0;
}

.from-bottom:after {
	bottom: 0;
	height: 0;
}

.from-bottom:hover:before,
.from-bottom:hover:after {
	height: 100%;
    width: 100%;
}


body {
	--def: #96B7C4; 	
	--inv: #EB1D50;
}

.button-glitch,
.button-glitch:after {
  width: fit-content;
  height: fit-content;
  line-height: 20px;
  padding: 10px 10px 10px 20px;
  font-size: 13px;
  font-family: 'Bebas Neue', sans-serif;
  background: linear-gradient(45deg, transparent 5%, #FF013C 5%);
  border: 0;
  color: #fff;
  letter-spacing: 3px;
  box-shadow: 6px 0px 0px #00E6F6;
  outline: transparent;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-glitch:after {
  --slice-0: inset(50% 50% 50% 50%);
  --slice-1: inset(80% -6px 0 0);
  --slice-2: inset(50% -6px 30% 0);
  --slice-3: inset(10% -6px 85% 0);
  --slice-4: inset(40% -6px 43% 0);
  --slice-5: inset(80% -6px 5% 0);
  
  content: 'MOKMETA SERVICES';
  display: flex;
  position: absolute;
  width: fill-available;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
  text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
  clip-path: var(--slice-0);
}

.button-glitch:hover:after {
  animation: 1s glitch;
  animation-timing-function: steps(2, end);
}

@keyframes glitch {
  0% {
    clip-path: var(--slice-1);
    transform: translate(-20px, -10px);
  }
  10% {
    clip-path: var(--slice-3);
    transform: translate(10px, 10px);
  }
  20% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 10px);
  }
  30% {
    clip-path: var(--slice-3);
    transform: translate(0px, 5px);
  }
  40% {
    clip-path: var(--slice-2);
    transform: translate(-5px, 0px);
  }
  50% {
    clip-path: var(--slice-3);
    transform: translate(5px, 0px);
  }
  60% {
    clip-path: var(--slice-4);
    transform: translate(5px, 10px);
  }
  70% {
    clip-path: var(--slice-2);
    transform: translate(-10px, 10px);
  }
  80% {
    clip-path: var(--slice-5);
    transform: translate(20px, -10px);
  }
  90% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 0px);
  }
  100% {
    clip-path: var(--slice-1);
    transform: translate(0);
  }
}

@media (min-width: 768px) {
  .button-glitch,
  .button-glitch:after {
    font-size: 18px;
    font-weight: 500;
    width: fit-content;
    padding: 0 20px 0 20px;
    height: auto;
    text-transform: uppercase;
    line-height: 58px;
  }
}

.stickfix{
    position: fixed;
    width: 50%;
    /* z-index: -1; */
    align-self: flex-start;
    top: 0;
    overflow-y: auto;
  }

  @media (max-width:900px) {
    .bgImg{
        background-position-y: center !important;
    }
    
  }

  .myblock {
	position: relative;
	margin: 300px auto 0;
	/* width: 500px;
	height: 250px; */
	background: linear-gradient(0deg, #000, #272727);
}

.myblock:before, .myblock:after {
	content: '';
	position: absolute;
	left: -2px;
	top: -2px;
	background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00,#ffff00, #ff0000, #fb0094, 
		#0000ff, #00ff00,#ffff00, #ff0000);
	background-size: 400%;
	width: calc(100% + 4px);
	height: calc(100% + 4px);
	z-index: -1;
	animation: steam 20s linear infinite;
}

@keyframes steam {
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 400% 0;
	}
	100% {
		background-position: 0 0;
	}
}

.myblock:after {
	filter: blur(50px);
}